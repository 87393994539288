var confirmDeletePopUpTemplate = require('./default/confirm_delete.handlebars');
var errorTemplate = require('./default/network_error.handlebars');
var hiddenPresetAccountTemplate = require('./default/preset_account_hidden.handlebars');
const lightbox = require('./default/lightbox.handlebars');
const header = require('./default/header.handlebars');
const presetHeader = require('./default/preset_header.handlebars');

var grouped = {};
grouped.pp3 = require('./default/network_grouped_smart.handlebars');
grouped.standalone = require('./standalone/network_grouped_smart.handlebars');

var networkSingle = {};
networkSingle.pp3 = require('./default/network_single.handlebars');
networkSingle.standalone = require('./standalone/network_single.handlebars');

var smartGrouped = {};
smartGrouped.pp3 = require('./default/network_grouped_smart.handlebars');
smartGrouped.standalone = require('./standalone/network_grouped_smart.handlebars');

var presetAccount = {};
presetAccount.pp3 = require('./default/preset_account.handlebars');
presetAccount.standalone = require('./standalone/preset_account.handlebars');

var account = {};
account.pp3 = require('./default/registered_account.handlebars');
account.standalone = require('./standalone/registered_account.handlebars');

var accountDelete = {};
accountDelete.pp3 = require('./default/registered_account_delete.handlebars');
accountDelete.standalone = require('./standalone/registered_account_delete.handlebars');

function getCorrectTemplate(data, templateName, templates) {
    return typeof templateName === 'string' && templateName.toLowerCase() === 'standalone'
        ? templates[templateName](data)
        : templates.pp3(data);
}

module.exports = {
    confirmDeletePopUpTemplate: confirmDeletePopUpTemplate,
    errorTemplate: errorTemplate,
    hiddenPresetAccountTemplate: hiddenPresetAccountTemplate,
    lightbox,
    header,
    presetHeader,
    groupedTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, grouped);
    },
    networkSingleTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, networkSingle);
    },
    smartGroupedTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, smartGrouped);
    },
    presetAccountTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, presetAccount);
    },
    accountTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, account);
    },
    accountDeleteTemplate: function (data, templateName) {
        return getCorrectTemplate(data, templateName, accountDelete);
    },
};
